import * as CommentAPI from '../apis/StoryCommentAPI';

export const fetchCommentList = (params) => {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            CommentAPI.fetchCommentList(params)
                .then((res) => {
                    if (res.status === 200 && res.data.code === 1) {
                        const commentList = res.data.data.list;
                        const total = res.data.data.total;
                        return resolve({ commentList, total });
                    }
                    return reject('err');
                })
                .catch((err) => {
                    return reject(err);
                });
        });
    };
}
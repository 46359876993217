import ApiEndpointConstants from '../constants/api/ApiEndpointConstants';
import api from './API';

export const submitProductOrder = (params) => {
    return api.post(
        `${ApiEndpointConstants.ORDER}`,
        params,
    );
}

export const queryProductOrder = (params) => {
    return api.post(
        `${ApiEndpointConstants.ORDER_LIST}`,
        params,
    );
}

export const queryProductDetail = (params) => {
    return api.post(
        `${ApiEndpointConstants.ORDER_DETAIL}`,
        params,
    );
}


import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay, Thumbs } from 'swiper';
import QuantityController from '../components/common/quantity/index';
import VisaIcon from '../icons/payment/visaIcon';
import OrderIcon from '../icons/OrderIcon';
import AmericanExpressIcon from '../icons/payment/americanExpressIcon';
import ApplePayIcon from '../icons/payment/applePayIcon';
import ExpressShippingIcon from '../icons/payment/expressShippingIcon';
import HundredPerQualityGuaranteeIcon from '../icons/payment/hundredPerQualityGuaranteeIcon';
import GooglePayIcon from '../icons/payment/googlePayIcon';
import MoneyBackIcon from '../icons/payment/moneyBackIcon';
import DiscoverCardIcon from '../icons/payment/discoverCardIcon';
import DinersClubIcon from '../icons/payment/dinersClubIcon';
import SslSecureIcon from '../icons/payment/sslSecureIcon';
import PaypalIcon from '../icons/payment/paypalIcon';
import MasterCardIcon from '../icons/payment/masterCardIcon';
import * as productActionCreator from '../actions/productActionCreator';
import * as productCommentActionCreator from '../actions/productCommentActionCreator';
import Loading from '../components/common/LoadingIndicator';
import * as ProductDetailSelectors from '../selectors/ProductDetailSelectors'
import MobileDetectUtil from '../utils/MobileDetectUtil';
import StarIcon from '../icons/StarIcon';


import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import 'swiper/scss/pagination';

import PagePathConstants from '../constants/router/PagePathConstants';
import ProductHelper from '../helpers/ProductHelper';
import XIcon from '../icons/XIcon';
import RDSButton from '../components/common/RDSButton';

class ProductDetailPage extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            showLoading: false,
            thumbsSwiper: null,
            marginWidth: 0,
            showShoppingCartModal: false,
            productCommentList: [],
            productCommentTotal: 0,
            activeCommentTab: 'all',
            showCommentImage: false,
            commentImg: '',

            productId: 6,
        }
        this.toggleLoading = this.toggleLoading.bind(this);
        this.setThumbsSwiper = this.setThumbsSwiper.bind(this);
        this.handleSelectSku = this.handleSelectSku.bind(this);
        this.renderWhiteArrowBtn = this.renderWhiteArrowBtn.bind(this);
        // this.toggleShoppingCartModal = this.toggleShoppingCartModal.bind(this);
        // this.addProductToCart = this.addProductToCart.bind(this);
        this.fetchProductCommentList = this.fetchProductCommentList.bind(this);
        // this.handleReceiveCoupon = this.handleReceiveCoupon.bind(this);
        this.handleCheckoutCurrentProduct = this.handleCheckoutCurrentProduct.bind(this);
        this.fetchProductAndComment = this.fetchProductAndComment.bind(this);
        this.handleViewOrderPage = this.handleViewOrderPage.bind(this);
        // this.handleChangeCommentTab = this.handleChangeCommentTab.bind(this);
        // this.closeCommentImg = this.closeCommentImg.bind(this);

        this.descContainerRef = React.createRef();
        this.quantityRef = React.createRef();
    }

    componentDidMount() {
        const { location, productActions } = this.props;
        // const productId = location.pathname.split(PagePathConstants.PRODUCT_DETAIL.replace(':id', ''))[1];

        this.fetchProductAndComment(this.state.productId);
    }

    // handleChangeCommentTab(activeCommentTab) {
    //     this.setState({ activeCommentTab });
    // }

    // closeCommentImg() {
    //     this.setState({ showCommentImage: false });
    // }

    handleViewOrderPage() {
        const {history, location} = this.props;
        history && history.push({ pathname: PagePathConstants.ORDER_SEARCH, search: location.search });
    }

    fetchProductAndComment(id) {
        const { productActions } = this.props;
        this.toggleLoading();
        productActions && productActions.fetchSingleProductById(id)
            .then(this.toggleLoading)
            .catch(this.toggleLoading)

        if (this.descContainerRef) {
            let offsetWidth = this.descContainerRef.current.offsetWidth;
            let marginWidth = (offsetWidth - 850) / 2;
            this.setState({ marginWidth });
        }

        this.renderWhiteArrowBtn();
        this.fetchProductCommentList();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.location.pathname !== this.props.location.pathname) {
            const { location, productActions } = nextProps;
            const productId = location.pathname.split(PagePathConstants.PRODUCT_DETAIL.replace(':id', ''))[1];
            this.fetchProductAndComment(productId);
        }
    }

    fetchProductCommentList() {
        const { commentActions } = this.props;
        commentActions && commentActions.fetchCommentList({productid: this.state.productId})
            .then((res) => {
                if (res && res.commentList) {
                    this.setState({ productCommentList: res.commentList, productCommentTotal: res.total });
                }
            })
            .catch(err => {
                console.error(err);
            })
    }

    renderWhiteArrowBtn() {
        let nextBtns = document.querySelectorAll('.swiper-button-next')
        let prevBtns = document.querySelectorAll('.swiper-button-prev')
        for (let i = 0; i < nextBtns.length; i++) {
            nextBtns[i].classList.add('btn-brand-primary');
            prevBtns[i].classList.add('btn-brand-primary');
        }
    }

    toggleLoading() {
        this.setState({ showLoading: !this.state.showLoading });
    }

    setThumbsSwiper(swiper) {
        this.setState({ thumbsSwiper: swiper });
    }

    handleSelectSku(index, incomingItem) {
        const { productActions, currentProduct } = this.props;
        let newProductInfo = Object.assign({}, currentProduct);
        if (newProductInfo.skulist) {
            newProductInfo.skulist[index].selectedValue = incomingItem;
        }
        productActions && productActions.updateCurrentProductSkuData(newProductInfo);
    }


    componentWillUnmount() {
        document.getElementById('root').classList.remove('overflow-y-hidden');
    }


    handleCheckoutCurrentProduct() {
        const { currentProduct, history, location } = this.props;
        currentProduct['quantity'] = this.quantityRef.current.state.quantity;
        ProductHelper.saveCheckoutProductList(false, currentProduct);
        history && history.push({ pathname: PagePathConstants.CHECKOUT_PAGE, search: location.search });
    }

    render() {
        const { showLoading, thumbsSwiper, marginWidth, productCommentList, productCommentTotal, showShoppingCartModal, activeCommentTab, showCommentImage, commentImg } = this.state;
        const { currentProduct } = this.props;
        const formatProductMap = currentProduct && currentProduct.image && JSON.parse(currentProduct.image);
        const productMap = formatProductMap;
        const isMobile = MobileDetectUtil.isMobile();
        let save;
        if (currentProduct) {
            save = (1 - ((parseFloat(currentProduct.currentPrice) / parseFloat(currentProduct.originalPrice)).toFixed(2))) * 100
            save = Math.round(save * 100) / 100;
        }
        const shippingPolicy = ['🔥 Free Shipping 🔥', 'Worldwide Shipping | Get Results or Your Money Back'];
        return (
            <div className='phone:tw-mt-0 tw-h-full phone:tw-h-auto tw-bg-[#eff0f5]'>
                {showLoading && <Loading />}
                <div className='tw-fixed tw-bottom-[80px] tw-right-[50px] tw-bg-brand-primary  tw-w-[60px] tw-h-[60px] tw-z-[10000] tw-rounded-full tw-overflow-hidden tw-flex tw-justify-center tw-items-center tw-shadow-xl' onClick={this.handleViewOrderPage}><OrderIcon size="30" color="white" className="tw-ml-[2px]"/></div>
                {showCommentImage && <div className='tw-w-full tw-h-full tw-fixed tw-left-0 tw-top-0 tw-flex tw-items-center tw-justify-center tw-bg-[rgba(0,0,0,0.7)] tw-z-[1000000]'>
                    <div className='tw-relative'>
                        <img src={commentImg} className='tw-h-[500px] phone:tw-hidden' alt='comment_img' />
                        <div className='tw-h-[500px] tw-w-[350px] tw-hidden phone:tw-block' style={{ backgroundImage: `url(${commentImg})`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }} />
                        <div onClick={this.closeCommentImg} className='tw-absolute tw-right-[-18px] tw-top-[-18px] tw-bg-[rgba(0,0,0,0.2)] tw-rounded-full tw-overflow-hidden tw-flex tw-items-center tw-justify-center tw-w-[36px] tw-h-[36px]'>
                            <XIcon size={24} color='white' />
                        </div>
                    </div>
                </div>}
                {/* <ShoppingCartModal fromPage='productDetail' showModal={showShoppingCartModal} toggleShoppingCartModal={this.toggleShoppingCartModal} {...this.props} /> */}
                <div className='tw-w-full tw-h-[44px] tw-bg-brand-primary'>
                    <div className='tw-max-w-[1400px] tw-h-full tw-mx-auto phone:tw-w-full'>
                        <Swiper
                            className='tw-w-full tw-h-full tw-text'
                            autoplay={{
                                delay: 2500,
                                disableOnInteraction: false,
                            }}
                            modules={[Navigation, Autoplay]}>
                            {shippingPolicy && shippingPolicy.map((item, i) => {
                                return (<SwiperSlide key={i}>
                                    <div className='tw-w-full tw-text-white tw-h-full tw-flex tw-justify-center tw-items-center phone:tw-text-[12px]'>{item}</div>
                                </SwiperSlide>)
                            })}
                        </Swiper>
                    </div>
                </div>
                <div className='tw-mt-[20px] tw-w-full tw-h-[calc(100%-64px)] phone:tw-h-auto tw-overflow-hidden tw-mx-auto tw-flex tw-justify-center phone:tw-flex-wrap'>
                    <div className='tw-w-[600px] phone:tw-w-full tw-h-[650px] phone:tw-h-auto tw-flex tw-items-start phone:tw-flex-col-reverse phone:tw-px-[20px]' style={{ marginLeft: (isMobile ? '0px' : marginWidth + 'px') }}>
                        <Swiper
                            direction={isMobile ? 'horizontal' : 'vertical'}
                            onSwiper={this.setThumbsSwiper}
                            slidesPerView={6}
                            className="tw-w-[100px] tw-h-[500px] phone:tw-w-full phone:tw-h-[50px] phone:tw-mt-[20px]">
                            {productMap && productMap.map((item, i) => {
                                return (<SwiperSlide key={i}>
                                    <div key={item} className='tw-w-[72px] tw-h-[72px] phone:tw-w-[50px] phone:tw-h-[50px] tw-rounded-[10px] tw-relative' style={{ backgroundImage: `url(${item})`, backgroundSize: 'contain', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}></div>
                                </SwiperSlide>)
                            })}
                        </Swiper>

                        <Swiper
                            className='tw-w-[485px] phone:tw-w-full '
                            // navigation
                            thumbs={{ swiper: thumbsSwiper }}
                            modules={[Thumbs]}>
                            {productMap && productMap.map((item, i) => {
                                return (<SwiperSlide key={i}>
                                    <div className='tw-w-[485px] phone:tw-w-full  tw-h-[650px] phone:tw-h-0 phone:tw-pb-[100%] tw-relative tw-bg-white' style={{ backgroundImage: `url(${item})`, backgroundSize: 'contain', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}></div>
                                </SwiperSlide>)
                            })}
                        </Swiper>
                    </div>
                    <div className='tw-w-[calc(100%-600px)] phone:tw-w-full tw-h-full phone:tw-h-auto tw-overflow-scroll scrollbar-hidden phone:tw-mt-[20px] phone:tw-mx-[20px]' ref={this.descContainerRef}>
                        <div className=' phone:tw-pl-0 tw-pl-[80px] tw-w-[calc(1440px-670px)] phone:tw-w-full tw-min-w-[450px] phone:tw-min-w-full playfair-font tw-text-body-text-1'>
                            <div className='tw-text-[26px] tw-text-brand-primary tw-font-bold'>{currentProduct && currentProduct.title}</div>
                            <div className='tw-text-[18px] tw-text-brand-primary tw-opacity-[0.7] tw-mt-[15px]'>{currentProduct && currentProduct.miaosu}</div>
                            <div className='tw-text-[24px] tw-mt-[15px] tw-flex tw-items-center tw-text-brand-primary'>
                                <div className=''>${currentProduct && currentProduct.currentPrice}</div>
                                <div className='tw-ml-[15px] tw-text-body-text-4 tw-text-[16px] tw-line-through'>${currentProduct && currentProduct.originalPrice}</div>
                                <div className='tw-ml-[15px] tw-h-[30px] tw-flex tw-justify-center tw-items-center tw-text-[18px] tw-bg-brand-primary tw-bg-opacity-[0.8] tw-text-white tw-font-[300] tw-px-[10px] pingFang-font'>Sale</div>
                                {/* <div className='tw-ml-[10px] tw-text-light-red sourceSansPro-font tw-text-[18px]'>{`Save ${save}%`}</div> */}
                            </div>

                            <div className='tw-mt-[20px] pingFang-font tw-text-body-text-5 '>
                                {currentProduct && currentProduct.skulist && currentProduct.skulist.map((sku, index) => {
                                    return <div className='tw-mb-[20px]' key={sku.title}>
                                        {/* <div className='tw-text-[16px] tw-flex tw-items-center'>{sku.title}: <div className='tw-font-[500] tw-ml-[10px]'>{sku.selectedValue}</div></div> */}
                                        <div className='tw-flex tw-flex-wrap tw-mt-[15px]'>{sku.data.map(item => {
                                            return <div key={item} className={`tw-min-w-[118px] tw-min-h-[50px] tw-border tw-border-brand-primary tw-rounded-[10px] tw-px-[5px]  tw-flex tw-justify-center tw-items-center tw-mr-[20px] tw-mb-[10px] tw-text-[16px] tw-text-brand-primary ${sku.selectedValue === item ? 'tw-bg-brand-primary tw-text-white' : ''}`} onClick={() => this.handleSelectSku(index, item)}>
                                                {/* {item.img && <div className='tw-w-[42px] tw-h-[42px] tw-rounded-[10px] tw-mr-[15px]' style={{ backgroundImage: `url(${item.img})`, backgroundPosition: 'center', backgroundSize: 'cover' }}></div>} */}
                                                <div className='tw-text-[18px] tw-max-w-[300px] overflow-ellipsis'>{item}</div>
                                            </div>
                                        })}</div>

                                    </div>
                                })}
                            </div>
                            <div className='tw-mt-[20px] pingFang-font tw-text-brand-primary'>
                                <div className='tw-text-[16px]'>Quantity:</div>
                                <div className='tw-mt-[8px]'>
                                    <QuantityController ref={this.quantityRef} />
                                </div>
                            </div>
                            <div className='tw-mt-[24px]'>
                                <RDSButton className="pingFang-font tw-w-[400px] phone:tw-w-full tw-mt-[24px] tw-border tw-border-brand-primary tw-text-brand-primary tw-rounded-[4px] tw-h-[50px] tw-font-[300] !tw-text-[18px]" color="white" onClick={this.handleCheckoutCurrentProduct}>Buy it now</RDSButton>
                            </div>
                            <div className='tw-my-[10px]'>90 Day Money Back Guarantee Secure checkout by</div>
                            <div className='tw-w-full tw-flex tw-justify-center tw-flex-wrap hs-payment-icon-container'>
                                <VisaIcon />  <AmericanExpressIcon /> <ApplePayIcon /> <ExpressShippingIcon /> <HundredPerQualityGuaranteeIcon /> <GooglePayIcon />
                                <MoneyBackIcon /> <DiscoverCardIcon /> <DinersClubIcon /> <SslSecureIcon /> <PaypalIcon /> <MasterCardIcon />
                            </div>
                        </div>

                        <div className='tw-w-[calc(1440px-670px)] phone:tw-w-full tw-flex tw-flex-col tw-items-center tw-text-body-text-light-grey sourceSansPro-font tw-my-[50px] '>
                            <div className="tw-text-[16px] tw-leading-[35px] tw-w-[70%] phone:tw-w-full">
                                <div className='tw-text-[14px] tw-mx-[20px] tw-text-brand-primary hs-product-description' dangerouslySetInnerHTML={{ __html: currentProduct && currentProduct.description }}></div>
                                <div className='tw-w-full tw-h-[1px] tw-bg-gray-line tw-mt-[30px]'></div>
                            </div>
                            <div className='tw-h-[1px] tw-bg-gray-line'></div>

                            {productCommentList && <div className='tw-w-[70%] tw-mt-[50px] phone:tw-mt-[30px] phone:tw-w-full phone:tw-px-[20px]'>
                                <div className='tw-flex tw-items-start phone:tw-flex-col'>
                                    <div className='tw-w-[30%] phone:tw-w-full phone:tw-text-center phone:tw-pb-[30px]'>
                                        <div className='tw-text-[22px] tw-mb-[30px] tw-font-bold'>Customer Reviews</div>
                                        <div className='tw-text-[12px]'>Here are what our customers say.</div>
                                        <div className='tw-flex tw-items-center tw-mt-[20px] phone:tw-justify-center'>
                                            <div className='tw-text-[38px]'>{4}</div>
                                            <div className='tw-ml-[20px]'>
                                                <div className='tw-flex tw-items-center'>
                                                    {new Array(4).fill(undefined).map(item => <StarIcon size={18} className="tw-mr-[8px]" />)}
                                                </div>
                                                <div className='tw-text-[16px] tw-mt-[10px]'>Total reviews: {productCommentTotal}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='tw-w-[70%] tw-pl-[20px] phone:tw-w-full phone:tw-mt-[10px]'>
                                        {<div className=''>
                                            {productCommentList.map(item => {
                                                return <div className='tw-border-b tw-py-[32px] '>
                                                    <div className='tw-text-[14px] tw-font-bold tw-mb-[20px] tw-flex tw-items-center'>
                                                        <div className='tw-w-[40px] tw-h-[40px] tw-rounded-full tw-overflow-hidden' style={{backgroundImage: `url(${item.logo})`, backgroundSize:'100%', backgroundRepeat:'no-repeat', backgroundPosition:'center'}}></div>
                                                        <div className='tw-ml-[10px]'>{item.nickname}</div>
                                                    </div>
                                                    <div className='tw-w-full tw-flex tw-items-center tw-mb-[20px]'>{item && item.star ? new Array(item.star).fill(undefined).map(item => <StarIcon size={18} className="tw-mr-[8px]" />) : ''}</div>
                                                    <div dangerouslySetInnerHTML={{__html: item && item.description}}></div>
                                                    
                                                </div>
                                            })}

                                        </div>}

                                    </div>
                                </div>
                            </div>}
                        </div>
                    </div>

                </div>

            </div>
        );
    }

}

function mapStateToProps(state) {
    return {
        currentProduct: ProductDetailSelectors.selectCurrentProduct(state),
    };
}

function mapDispatchToProps(dispatch) {
    return {
        productActions: bindActionCreators(productActionCreator, dispatch),
        commentActions: bindActionCreators(productCommentActionCreator, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetailPage);

import moment from 'moment';
import isNil from 'lodash/isNil';

// import Translate from '../components/common/translate/Translate';

export const currentTimestampInSeconds = () => Math.floor(Date.now() / 1000);

/*
* return ISO 8601 date string based on date/time related args
*/
export const getDateTimeISOString = (date, hour, minute, meridian) => {
  let str = null;
  if (!isNil(date) && !isNil(hour) && !isNil(minute)) {
    let hr = parseInt(hour, 10);
    date.setMinutes(parseInt(minute, 10));
    if (meridian && meridian === 'PM' && hr !== 12) {
      hr += 12;
      date.setHours(hr);
    } else {
      date.setHours(hr);
    }
    str = date.toISOString();
  }
  return str;
};

export const getDateTimeISOStringWith12AMSupport = (date, hour, minute, meridian) => {
  let preciseHour = hour;
  if ((hour === 12 || hour === '12') && meridian === 'AM') {
    preciseHour = 0;
  }
  return getDateTimeISOString(date, preciseHour, minute, meridian);
}

/*
* return 'MM/DD/yyyy' string from date
* e.g. '02/09/2018'
*/
export const getMMDDFormattedDate = (date) => {
  if (!date) return '';
  let year = date.getFullYear();
  let month = (1 + date.getMonth()).toString();
  month = month.length > 1 ? month : '0' + month;

  let day = date.getDate().toString();
  day = day.length > 1 ? day : '0' + day;

  return month + '-' + day + '-' + year;
};

/*
* return 'in_school' or 'out_of_school' depending on user's local hour
*/
export const getLocalTimeOfDay = () => {
  const date            = new Date(),
        localHour       = date.getHours(),
        localMinutes    = date.getMinutes(),
        weekdays        = [1, 2, 3, 4, 5],
        isWeekday       = weekdays.includes(date.getDay()),
        isInSchoolHours = localHour >= 7 && (localHour < 15 || (localHour === 15 && localMinutes < 30));

  return (isInSchoolHours && isWeekday) ? 'in_school' : 'out_of_school';
};

export const getCurrentYear = () => {
  return (new Date()).getFullYear();
};

/*
* Check to see if date falls within the current year
*/
export const isDateCurrentYear = (date) => {
  return moment(date).year() === getCurrentYear();
}

/*
* Get current hour in 24 hr format
*/
export const getCurrentHour = () => {
  return new Date().getHours();
}

/*
* Get current minutes
*/
export const getCurrentMinutes = () => {
  return new Date().getMinutes();
}

/*
* Get current meridian
*/
export const getCurrentMeridian = () => {
  return getCurrentHour() >= 12 ? 'PM' : 'AM' ;
}

/*
* Get current timestamp
*/
export const getCurrentTimeStamp = () => {
  return moment(getCurrentHour()+':'+getCurrentMinutes()+' '+getCurrentMeridian(), 'hh:mm A').unix();
}

/*
* Get current date in YYYY-MM-DD format
*/
export const getCurrentDate = () => {
  return moment(new Date()).format('YYYY-MM-DD');
}

/*
* Get Scheduled date in YYYY-MM-DD format
*/
export const getScheduledDate = (dueDate) => {
  return dueDate && moment(dueDate).format('YYYY-MM-DD');
}


/*
* Get time diff like '16 hours ago' or '4 days left'
*/

// Math.trunc polyfill for IE:
if (!Math.trunc) {
  Math.trunc = function(v) {
    v = +v;
    return (v - v % 1)   ||   (!isFinite(v) || v === 0 ? v : v < 0 ? -0 : 0);
  };
}


/*
* Get Scheduled date in the format given by param.
*/

export const formatDate = (dueDate, format) => {
  return dueDate && moment(dueDate).locale('en').format(format);
}

/*
* Is late subssion date to corresponding due date.
*/
export const getIsLate = (dueDate, submissionDate) => {
  if (isNil(dueDate) || isNil(submissionDate)) return false;
  return (moment(submissionDate).diff(moment(dueDate))) >= 0;
}

export const timerInMinutes = (ms, showHours) => {

  if (ms <= 0) return '00:00';

  const seconds = Math.floor(ms/1000);
  const minutes = Math.floor(seconds / 60) % 60;
  const hours = Math.floor(seconds / 3600);

  const format = n => `${n < 10 ? '0' : ''}${n}`;

  return `${hours > 0 ? format(hours) + ':' : (showHours ? '00:' : '')}${format(minutes)}:${format(seconds % 60)}`;
}


export const leapYear = (year) => {
  year = parseInt(year);
  return ((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0);
}

export const dropdownDayOptions = (translate, year, month, showEmpty = true) => {
  const febDays = (year && leapYear(year)) ? 29 : 28;
  const monthDays = (month === 9  || month === 4 || month === 6 || month === 11) ? 30 : 31;
  const maxDay = month && month === 2 ? febDays : monthDays;
  let days = [];
  if (showEmpty) {
    days.push([translate('day', "Day"), null]);
  }
  for(let i = 1 ; i <= maxDay; i++){
    days.push([i, i]);
  }
  return(days);
}

export const dropdownMonthOptions = (translate, showEmpty = true) => {
  let months = [];
  if (showEmpty) {
    months.push([translate('month', "Month"), null]);
  }
  months.push([translate('january', "January"), 1]);
  months.push([translate('february', "February"), 2]);
  months.push([translate('march', "March"), 3]);
  months.push([translate('april', "April"), 4]);
  months.push([translate('may', "May"), 5]);
  months.push([translate('june', "June"), 6]);
  months.push([translate('july', "July"), 7]);
  months.push([translate('august', "August"), 8]);
  months.push([translate('september', "September"), 9]);
  months.push([translate('october', "October"), 10]);
  months.push([translate('november', "November"), 11]);
  months.push([translate('december', "December"), 12]);
  return months;
}

export const dropdownYearOptions = (translate, showEmpty = true) => {
  let years = [];
  let today = new Date();
  if (showEmpty) {
    years.push([translate('year', "Year"), null]);
  }
  for(let i = (today.getFullYear() - 100) ; i < (today.getFullYear() + 1); i++){
    years.push([i, i]);
  }
  return years;
}



/*
* Get current ISO 8601 date string
*/
export const getCurrentDateTimeISOString = () => {
  return moment(getCurrentHour()+':'+getCurrentMinutes()+' '+getCurrentMeridian(), 'hh:mm A');
}

export const getDateFromDateString = (dateStr) => {
  const dob = dateStr.split('-');
  return {
    day: dob[2],
    month: dob[1],
    year: dob[0],
  };
}

export const getFriendlyTime = (date) => {
  const currentYear        = (new Date()).getFullYear();
  const showYear           = moment(date).year() !== currentYear;
  const timestamp          = `${showYear ? moment(date).format('ll') : moment(date).format('MMMM D')} · ${moment(date).format('h:mm A')}`;
  const relativeTimestamp  = moment().diff(date, 'days') > 2 ? timestamp : moment(date).fromNow();
  return relativeTimestamp;
}

export const showFullTimeFormat = (date) => {
  const format = `${moment(date).format('MMMM D')} · ${moment(date).format('h:mm A')}`;
  return format;
}
import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PagePathConstants from '../constants/router/PagePathConstants';
import * as ResponsivePageUtils from '../utils/ResponsivePageUtils';
import Loading from '../components/common/LoadingIndicator';
import EarthIcon from '../icons/EarthIcon';
import Facebook from '../images/facebook.svg';
import Twitter from '../images/twitter.svg';
import Email from '../images/email.svg';
import * as storyActionCreator from '../actions/storyActionCreator';
import * as storyCommentActionCreator from '../actions/storyCommentActionCreator';
import * as DateTimeUtil from '../utils/DateTimeUtil';

class AdvertisingPage extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            story: null,
            storyId: 7,
            commentList: [],
            commentTotal: 0,
            showLoading: false
        }

        this.routeToSingleProductPage = this.routeToSingleProductPage.bind(this);
        this.getStoryInfoById = this.getStoryInfoById.bind(this);
        this.getStoryCommentListById = this.getStoryCommentListById.bind(this);
        this.toggleLoading = this.toggleLoading.bind(this);
    }

    componentDidMount() {
        this.getStoryInfoById();
        this.getStoryCommentListById();
    }

    getStoryInfoById() {
        const { location, storyActions } = this.props;
        const { storyId } = this.state;
        this.toggleLoading();
        storyActions.getStoryInfoById(storyId)
            .then(res => {
                this.toggleLoading();
                const storyInfo = res;
                this.setState({
                    story: storyInfo
                });
            })
            .catch((err) => {
                this.toggleLoading();
                console.error(err);
            });
    }

    toggleLoading() {
        this.setState({ showLoading: !this.state.showLoading });
    }

    getStoryCommentListById() {
        let params = { 'tieziid': this.state.storyId };
        const { commentActions } = this.props;
        return commentActions && commentActions.fetchCommentList(params)
            .then(data => {
                console.error(data);
                if (data.commentList) {
                    const { commentList, total } = data;
                    this.setState({ commentList, commentTotal: total });
                }
            })
            .catch();
    }

    routeToSingleProductPage() {
        const { history, location } = this.props;
        history && history.push({ pathname: PagePathConstants.SINGLE_PRODUCT, search: location.search });
    }

    randomDate(index) {
        // 随机生成0-11的数字
        const randomMonthNum = Math.floor(Math.random() * 11);
        // 随机生成1-30数字
        const randomDateNum = Math.ceil(Math.random() * 30);
        // 随机生成1-24 数字
        const randomHourNum = Math.ceil(Math.random() * 24);
        // 随机生成1-60 数字
        const randomMinuteNum = Math.ceil(Math.random() * 60);
        let time;
        if (index < 3) {
            time = moment().subtract(index, 'days').hour(randomHourNum)
                .minutes(randomMinuteNum)
                .seconds(randomMinuteNum)
        } else if (index >= 3 && index < 6) {
            time = moment().subtract(index, 'days').hour(randomHourNum)
                .minutes(randomMinuteNum)
                .seconds(randomMinuteNum)
        } else {
            time = moment().subtract(index < 9 ? index : 8, 'days').hour(randomHourNum)
                .minutes(randomMinuteNum)
                .seconds(randomMinuteNum)
        }
        return DateTimeUtil.getFriendlyTime(time);
    }

    render() {
        const isMobile = ResponsivePageUtils.isMobileScreen();
        const { story, commentList, showLoading } = this.state;
        const mockReportTime = DateTimeUtil.getFriendlyTime(moment(new Date()).subtract(10, 'days').subtract(2, 'hours').subtract(30, 'minutes'));
        return (
            <div className='tw-w-full  pingFang-font ad-bg-container' >
                {showLoading && <Loading />}
                <div className='tw-w-full tw-h-[62px] tw-bg-[#036] tw-flex tw-justify-center'>
                    <div className='tw-w-[700px] tw-px-[20px] phone:tw-w-full tw-flex tw-justify-between tw-items-center tw-h-[62px]'>
                        <div className='tw-text-white tw-text-[26px] phone:tw-text-[16px] tw-font-bold'>News</div>
                        <div className=' tw-text-[14px] phone:tw-text-[14px] tw-rounded-[5px] tw-px-[15px] tw-py-[7px] tw-bg-[#ebf5ff] tw-text-[#0064D1] tw-font-bold tw-cursor-pointer' onClick={this.routeToSingleProductPage}>Apply</div>
                    </div>
                </div>
                <div className='tw-max-w-[700px]   tw-mx-auto tw-text-advertising-text tw-bg-white' style={{ boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px' }}>
                    <div className='tw-pb-[18px]'>
                        <div className='tw-flex tw-items-center tw-px-[20px] tw-pt-[20px]'>
                            <div className='tw-shrink-0 tw-w-[40px] tw-h-[40px] tw-rounded-full tw-overflow-hidden' style={{ backgroundImage: `url(${story && story.logo})`, backgroundSize: '100%', backgroudRepeat: 'no-repeat', backgroundPosition: 'center' }}></div>
                            <div className='tw-w-[500px] phone:tw-w-[200px] tw-ml-[20px] tw-h-[40px] tw-flex tw-flex-col tw-justify-between tw-items-start'>
                                <div className='tw-w-full tw-text-[15px] tw-font-bold tw-whitespace-nowrap tw-overflow-hidden tw-text-ellipsis'>{story && story.nickname}</div>
                                <div className='tw-text-[13px] tw-text-body-text-grey tw-flex tw-items-center'><EarthIcon width="18" height="18" color='#737B8B' className="tw-mr-[8px]" />{mockReportTime}</div>
                            </div>
                        </div>
                        <div className='tw-px-[20px] tw-text-[15px] tw-mt-[20px] hs-story-description' dangerouslySetInnerHTML={{ __html: story && story.description }}></div>
                        <div className="tw-mx-[20px] tw-flex tw-justify-between tw-items-center tw-border-b  tw-border-advertising-line tw-py-[15px] ">
                            <div className="tw-flex tw-items-center tw-w-[250px]">
                                <div className='tw-shrink-0 tw-w-[32px] tw-h-[32px] tw-rounded-full tw-overflow-hidden tw-mr-[10px]' style={{ backgroundImage: `url(${story && story.logo})`, backgroundSize: '100%', backgroudRepeat: 'no-repeat', backgroundPosition: 'center' }}></div>
                                <p className='tw-text-[14px] tw-w-[calc(100% - 42px)] tw-whitespace-nowrap tw-overflow-hidden tw-text-ellipsis'>By <strong> {story && story.nickname} </strong> </p>
                            </div>
                            <div className="tw-flex tw-justify-between tw-items-center tw-w-[120px]">
                                <img className='tw-w-[32px] tw-h-[32px]' alt="ad-img-social" src={Facebook} />
                                <img className='tw-w-[32px] tw-h-[32px]' alt="ad-img-social" src={Twitter} />
                                <img className='tw-w-[32px] tw-h-[32px]' alt="ad-img-social" src={Email} />
                            </div>
                        </div>
                        <div className='tw-mx-[20px] tw-py-[15px] tw-flex tw-justify-between tw-items-center tw-border-advertising-line tw-border-opacity-[0.2] tw-border-b'>
                            <div className='tw-text-[13px] tw-flex tw-items-center'>
                                {/* <LikeIcon width="20" height="20" /> */}
                                <svg  width="24" height="24" viewBox="0 0 24 24">
                                    <path d="M12,21.35L10.55,20.03C5.4,15.36 2,12.27 2,8.5C2,5.41 4.42,3 7.5,3C9.24,3 10.91,3.81 12,5.08C13.09,3.81 14.76,3 16.5,3C19.58,3 22,5.41 22,8.5C22,12.27 18.6,15.36 13.45,20.03L12,21.35Z" fill="#ea442b"></path>
                                </svg>
                                <div className='tw-text-body-text-grey tw-ml-[6px]'>15K</div>
                            </div>
                            <div className='tw-flex tw-items-center tw-text-body-text-grey tw-text-[13px]'>
                                <span>425 comments</span>
                                <span className='tw-ml-[30px]'>820 shares</span>
                            </div>
                        </div>
                        <div className='tw-mt-[15px] tw-mx-[20px]'>
                            {commentList && commentList.map((comment, index) => {
                                const commentTime = this.randomDate(index);
                                return (<div className='tw-mb-[18px] tw-flex tw-items-start'>
                                    <div className='tw-mt-[4px] tw-w-[32px] tw-h-[32px] tw-rounded-full tw-overflow-hidden' style={{ backgroundImage: `url(${comment.logo})`, backgroundSize: '100%', backgroudRepeat: 'no-repeat', backgroundPosition: 'center' }}></div>
                                    <div className='tw-ml-[5px]'>
                                        <div className='tw-px-[12px] tw-py-[8px] tw-rounded-[20px] tw-bg-[#F0F2F5]'>
                                            <div className='tw-font-bold tw-text-[12px]'>{comment && comment.nickname}</div>
                                            <div className='tw-text-[12px] tw-mt-[4px]' dangerouslySetInnerHTML={{ __html: comment && comment.description }}></div>
                                        </div>
                                        <div className='tw-mt-[5px] tw-px-[12px] tw-flex tw-items-center tw-text-[12px] tw-text-body-text-grey'>
                                            <div className='tw-flex tw-items-center'>
                                                <EarthIcon width="16" height="16" color='#737B8B' className="tw-mr-[8px]" />
                                                <span className=''>{commentTime}</span>
                                            </div>
                                            <div className='tw-ml-[18px] tw-flex tw-items-center'>
                                                <span className='tw-mr-[0px] tw-text-[12px]'>{Math.ceil(Math.random() * 30)}</span>
                                                {/* <LikeIcon width="16" height="16" /> */}

                                                <button class="like-button tw-mt-[2px]">
                                                    <div class="like-wrapper">
                                                        <div class="ripple"></div>
                                                        <svg class="heart" width="24" height="24" viewBox="0 0 24 24">
                                                            <path d="M12,21.35L10.55,20.03C5.4,15.36 2,12.27 2,8.5C2,5.41 4.42,3 7.5,3C9.24,3 10.91,3.81 12,5.08C13.09,3.81 14.76,3 16.5,3C19.58,3 22,5.41 22,8.5C22,12.27 18.6,15.36 13.45,20.03L12,21.35Z"></path>
                                                        </svg>
                                                        <div class="particles" style={{ '--total-particles': 6 }}>
                                                            <div class="particle" style={{ '--i': 1, '--color': "#7642F0" }}></div>
                                                            <div class="particle" style={{ '--i': 2, '--color': "#AFD27F" }}></div>
                                                            <div class="particle" style={{ '--i': 3, '--color': "#DE8F4F" }}></div>
                                                            <div class="particle" style={{ '--i': 4, '--color': "#D0516B" }}></div>
                                                            <div class="particle" style={{ '--i': 5, '--color': "#5686F2" }}></div>
                                                            <div class="particle" style={{ '--i': 6, '--color': "#D53EF3" }}></div>
                                                        </div>
                                                    </div>
                                                </button>

                                            </div>
                                        </div>
                                    </div>
                                </div>)
                            })}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

// function mapStateToProps(state) {
//     return {
//         selectedCountryCode: VerifyOptionSelectors.selectCountryCode(state),
//         ssn: VerifyOptionSelectors.selectSsn(state),
//     };
// }

function mapDispatchToProps(dispatch) {
    return {
        storyActions: bindActionCreators(storyActionCreator, dispatch),
        commentActions: bindActionCreators(storyCommentActionCreator, dispatch),
    };
}

export default connect(null, mapDispatchToProps)(AdvertisingPage);

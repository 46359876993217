import { BASE_URL } from "../constants/api/ApiEndpointConstants";
import ActionConstants from "../constants/actions/ActionConstants";
import * as ProductAPI from "../apis/ProductAPI";
import { showErrorGlobalAlert, showInfoGlobalAlert } from './globalAlertActionCreator';

export const updateSingleProduct = (data) => ({
  type: ActionConstants.UPDATE_SINGLE_PRODUCT,
  payload: data,
});

export const updateCurrentProductSkuData = (data) => ({
  type: ActionConstants.UPDATE_PRODUCT_SKUDATA,
  payload: data,
});

export const fetchSingleProductById = (id) => {
  return function (dispatch, getState) {
    return new Promise((resolve, reject) => {
      ProductAPI.getProductDetailById(id)
        .then((res) => {
          if (res.status === 200 && res.data.code === 1) {
            dispatch(updateSingleProduct(res.data.data));
            return resolve(res.data.data);
          }
          return reject(res.data.msg);
        })
        .catch((err) => {
          if (err && err.response && err.response.data) {
            dispatch(showErrorGlobalAlert(err.response.data.msg));
          } else {
            dispatch(showErrorGlobalAlert('product-fetch-error'));
          }
          return reject('err');
        });
    });

  };
};

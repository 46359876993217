const PagePathConstants = {
  ROOT_PAGE                     : "/",
  SINGLE_PRODUCT                : "/product",
  CHECKOUT_PAGE                 : "/checkout",
  ORDER_CALLBACK                : "/order-callback",
  ORDER_SEARCH                  : "/order-search",
  ORDER_DETAIL                  : "/order-detail",
  LOGIN_PAGE                    : "/login",
};

export default PagePathConstants;

import ApiEndpointConstants from '../constants/api/ApiEndpointConstants';
import api from './API';

export const getProductDetailById = (id) => {
    return api.post(
        `${ApiEndpointConstants.PRODUCT_DETAIL}`,
        { id },
    );
}

// export const fetchProductsByTagId = (params) => {
//     return api.post(
//         `${ApiEndpointConstants.PRODUCT_LIST}`,
//         params,
//     );
// }

// export const fetchProductsByCategoryId = (params) => {
//     return api.post(
//         `${ApiEndpointConstants.PRODUCT_LIST}`,
//         params,
//     );
// }

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import queryString from "query-string";
import PagePathConstants from '../constants/router/PagePathConstants';
import RDSButton from '../components/common/RDSButton';
import OrderSuccessIcon from '../icons/OrderSuccessIcon';

class OrderStatusPage extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            orderState: '',
            successfullyText: 'Thank you for your order! We will send an email to %email to follow up on the order status, please pay attention to answer it.',
            failedText: 'Fail to pay! please try again later.',
        }
        this.backToHome = this.backToHome.bind(this);
    }

    componentDidMount() {
        const { location } = this.props;
        const queryParams = queryString.parse(location.search);
        const {state, email} = queryParams;
        this.setState({
            orderState: state,
            successfullyText: this.state.successfullyText.replaceAll('%email', email)
        })
    }

    backToHome() {
        const { history, location } = this.props;
        history && history.push({pathname: PagePathConstants.SINGLE_PRODUCT, search: location.search});
    }

    render() {
        const {successfullyText, failedText, orderState} = this.state;
        return (
            <div className='tw-w-full tw-h-full gotham-font tw-text-[#393c41] ad-bg-container'>
                <div className='tw-w-full tw-h-full phone:tw-h-full'>
                    <div className='tw-w-[750px] tw-h-[500px] phone:tw-w-full tw-mx-auto phone:tw-px-[20px] phone:tw-w-full '>
                        {orderState === 'success' && <div className='tw-flex tw-flex-col tw-justify-between tw-items-center'>
                            <OrderSuccessIcon size={500} color='#27458f'/>
                            <div className='tw-w-full tw-text-[18px] phone:tw-text-[14px] tw-font-[500] tw-text-center phone:tw-text-left phone:tw-text-[18px] tw-text-brand-primary' dangerouslySetInnerHTML={{ __html: successfullyText }}></div>
                        </div>}
                        {orderState === 'cancel' && <div className='tw-flex tw-flex-col tw-justify-between tw-items-center'>
                            <OrderSuccessIcon size={500} color='#27458f'/>
                            <div className='tw-mt-[30px] tw-w-full tw-text-[18px] phone:tw-text-[14px] tw-font-[500] tw-text-light-red tw-text-center phone:tw-text-[18px] tw-text-brand-primary'>{failedText}</div>
                        </div>}
                        <div className='tw-w-full tw-flex tw-justify-center tw-items-center tw-mt-[30px] tw-text-white tw-mt-[20px]'>
                            <RDSButton
                                color="primary"
                                onClick={this.backToHome}>
                                <span>Back to Home</span>
                            </RDSButton>
                        </div>
                    </div>

                </div>
            </div>
        );
    }

}


function mapStateToProps(state) {
    return {
    };
}

function mapDispatchToProps(dispatch) {
    return {
        // productActions: bindActionCreators(productActionCreator, dispatch),
        // tagActions: bindActionCreators(tagActionCreator, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderStatusPage);

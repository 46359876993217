import React, { Component } from 'react';
import {
  Switch,
  HashRouter,
  Route,
} from 'react-router-dom';
import PagePathConstants from './constants/router/PagePathConstants';
import AdvertisingPage from './pages/AdvertisingPage';
import ProductDetailPage from './pages/ProductDetailPage';
import CheckoutPage from './pages/CheckoutPage';
import OrderStatusPage from './pages/OrderStatusPage';
import OrderListPage from './pages/OrderListPage';
import OrderDetailPage from './pages/OrderDetailPage';
import './App.css';
import { setAPIBaseUrl } from './apis/API';

class App extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    console.log(window.location.protocol + "//" + window.location.host)
    if (window.location.host.indexOf('localhost') < 0) {
      const baseUrl = window.location.protocol + "//" + window.location.host;
      setAPIBaseUrl(baseUrl);
    }
  }

  render() {
    return (
      <HashRouter>
        <React.Fragment>
          <Switch>
            <Route path={PagePathConstants.SINGLE_PRODUCT} component={ProductDetailPage} />
            <Route path={PagePathConstants.CHECKOUT_PAGE} component={CheckoutPage} />
            <Route path={PagePathConstants.ORDER_CALLBACK} component={OrderStatusPage} />
            <Route path={PagePathConstants.ORDER_SEARCH} component={OrderListPage} />
            <Route path={PagePathConstants.ORDER_DETAIL} component={OrderDetailPage} />
            <Route path={PagePathConstants.ROOT_PAGE} component={AdvertisingPage} />
          </Switch>
        </React.Fragment>
      </HashRouter>

    );
  }
}
export default App;
import React, { Component } from 'react';

import AddIcon from '../../../icons/AddIcon';
import SubIcon from '../../../icons/SubIcon';
import classNames from 'classnames';

class QuantityController extends Component {
    constructor(props) {
        super(props);
        this.state = {
            quantity: props.quantity || 1,
        }
        this.handleAddQuantity = this.handleAddQuantity.bind(this);
        this.handleSubQuantity = this.handleSubQuantity.bind(this);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.quantity !== this.props.quantity) {
            this.setState({quantity: nextProps.quantity});
        }
    }

    handleAddQuantity() {
        const {addCallback, index} = this.props;
        let { quantity } = this.state;
        this.setState({ quantity: quantity + 1 }, () => {
            addCallback && addCallback(this.state.quantity, index);
        })
    }

    handleSubQuantity() {
        const {subCallback, index} = this.props;
        let { quantity } = this.state;
        if (quantity === 1) return;
        this.setState({quantity: quantity - 1}, () => {
            subCallback && subCallback(this.state.quantity, index);
        });
    }
    render() {
        const { quantity } = this.state;
        const {className} = this.props;
        return (
            <div className={classNames(className, 'tw-w-[118px] tw-rounded-[10px] tw-px-[8px] tw-py-[9px] tw-border tw-border-brand-primary tw-flex tw-items-center tw-justify-between')}>
                <div className={`tw-w-[30px] tw-h-[30px] tw-flex tw-justify-center tw-items-center quantity-icon ${quantity === 1 ? 'disabled' : ''}`} onClick={this.handleSubQuantity}>
                    <SubIcon color={quantity === 1 ? 'rgba(136, 138, 139, 0.4)' : '#27458f'} size={20} />
                </div>
                <div className='tw-flex tw-justify-center tw-items-center tw-w-[40px] tw-h-[30px] tw-select-none tw-text-brand-primary'>{quantity}</div>
                <div className={`tw-w-[30px] tw-h-[30px] tw-flex tw-justify-center tw-items-center quantity-icon`} onClick={this.handleAddQuantity}>
                    <AddIcon color='#27458f' size={20} />
                </div>
            </div>
        );
    }
}

export default QuantityController;

import { combineReducers } from 'redux-immutable';
import { routerReducer } from 'react-router-redux';
import ReducerNameConstants from '../constants/reducers/ReducerNameConstants';
import { ProductDetailReducer } from './ProductDetailReducer';
import { UserReducer } from './UserReducer';

const appReducer = combineReducers({
  [ReducerNameConstants.PRODUCT_DETAIL]: ProductDetailReducer,
  [ReducerNameConstants.USER]: UserReducer,
  router: routerReducer,
});

const rootReducer = (state, action) => {
  let newState = state;
  return appReducer(newState, action);
};

export default rootReducer;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import queryString from "query-string";
import PagePathConstants from '../constants/router/PagePathConstants';
import RDSButton from '../components/common/RDSButton';
import * as orderActionCreator from '../actions/orderActionCreator';
// import EDSInput from '../components/common/EDSInput';
import { Input } from 'reactstrap';
import SearchIcon from '../icons/SearchIcon';
import OrderSearchIcon from '../icons/OrderSearchIcon';
import RdsBackIcon from '../icons/RdsBackIcon';
import SvgIcon from '../components/common/SvgIcon';
import ColorConstants from '../constants/ColorConstants';
import I18N from '../i18n/index';
import Pagination from '@material-ui/lab/Pagination';
import * as DateTimeUtil from '../utils/DateTimeUtil';
import Loading from '../components/common/LoadingIndicator';

class OrderDetailPage extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            id: '',
            orderInfo: null,
            showLoading: false,
        }
        this.handleBack = this.handleBack.bind(this);
        this.queryOrder = this.queryOrder.bind(this);
        this.toggleLoading = this.toggleLoading.bind(this);

    }

    componentDidMount() {
        const { location } = this.props;
        const queryParams = queryString.parse(location.search);
        this.setState({ id: queryParams.id }, this.queryOrder);
    }

    queryOrder() {
        const { orderActions } = this.props;
        this.toggleLoading();
        orderActions && orderActions.queryOrderDetail({ id: this.state.id })
            .then(res => {
                this.toggleLoading();
                console.error(res);
                let orderInfo = res.data;

                const productImg = orderInfo && orderInfo.goodsList && orderInfo.goodsList[0] && orderInfo.goodsList[0].goods_image && JSON.parse(orderInfo.goodsList[0].goods_image)[0];
                const selectedSku = orderInfo && orderInfo.goodsList && orderInfo.goodsList[0] && orderInfo.goodsList[0].goods_image && JSON.parse(orderInfo.goodsList[0].goods_sku)[0];
                const formatSku = selectedSku && selectedSku.title + ' : ' + selectedSku.data[0];
                const num = orderInfo && orderInfo.goodsList && orderInfo.goodsList[0] && orderInfo.goodsList[0].goods_num;
                orderInfo = Object.assign({}, orderInfo, {productImg, formatSku, num});
                this.setState({ orderInfo });
            })
            .catch((err) => {
                this.toggleLoading();
                console.error(err);
            })
    }

    toggleLoading() {
        this.setState({ showLoading: !this.state.showLoading });
    }

    handleBack() {
        const { history } = this.props;
        history && history.goBack();
    }


    render() {
        const { orderInfo, showLoading } = this.state;
        const productImg = '';
        return (
            <div className='tw-w-full tw-h-full gotham-font tw-text-[#393c41] ad-bg-container'>
                {showLoading && <Loading />}
                <div className='tw-w-full tw-h-full phone:tw-h-full tw-overflow-scroll tw-pb-[50px]'>
                <div className='tw-h-[50px] tw-flex tw-items-center tw-pl-[80px] phone:tw-pl-[30px] tw-pt-[30px] tw-text-brand-primary' onClick={this.handleBack}><RdsBackIcon color={ColorConstants.brand.primary} /> Back</div>
                    <div className='tw-w-[750px] tw-h-[500px] phone:tw-w-full tw-mx-auto phone:tw-px-[20px] phone:tw-w-full '>

                        <div className='tw-flex tw-flex-col tw-items-center tw-w-full tw-px-[20px] tw-relative'>
                            <OrderSearchIcon size={400} />

                            <div className='tw-w-full tw-mb-[60px]'>
                                <div className='tw-px-[20px] tw-py-[20px] tw-mb-[25px] tw-flex tw-items-center tw-bg-white'>
                                    <div className='tw-ml-[8px] tw-font-bold'>Order Detail</div>
                                </div>
                                <div className='tw-w-full phone:tw-w-full pad:tw-w-full phone:tw-text-[14px] '>
                                    <div className='tw-flex tw-items-center '>
                                        <div className='tw-w-[30%] tw-h-[60px] phone:tw-h-[100px] tw-py-[10px] tw-px-[30px] phone:tw-px-[10px] tw-border tw-flex tw-items-center tw-font-bold'><div className='tw-line-clamp-2 phone:tw-line-clamp-3 tw-break-words'>OrderNo</div></div>
                                        <div className='tw-w-[70%] tw-h-[60px] phone:tw-h-[100px] tw-py-[10px] tw-px-[30px] tw-border tw-flex tw-items-center'><div className='tw-line-clamp-2 phone:tw-line-clamp-3 tw-break-all'>{orderInfo && orderInfo.orderNu}</div></div>
                                    </div>
                                    <div className='tw-flex tw-items-center '>
                                        <div className='tw-w-[30%] tw-h-[100px] phone:tw-h-[100px] tw-py-[10px] tw-px-[30px] phone:tw-px-[10px] tw-border tw-flex tw-items-center tw-font-bold'><div className='tw-line-clamp-2 phone:tw-line-clamp-3 tw-break-words'>Product</div></div>
                                        <div className='tw-w-[70%] tw-h-[100px] phone:tw-h-[100px] tw-py-[10px] tw-px-[30px] tw-border tw-flex tw-items-center'><div className='tw-line-clamp-2 phone:tw-line-clamp-3 tw-break-all'><div className='tw-w-[80px] tw-h-[80px] ' style={{ backgroundImage: `url(${orderInfo && orderInfo.productImg})`, backgroundSize: '100%', backgroundRepeat: 'no-repeat' }}></div></div></div>
                                    </div>
                                    <div className='tw-flex tw-items-center '>
                                        <div className='tw-w-[30%] tw-h-[60px] phone:tw-h-[100px] tw-py-[10px] tw-px-[30px] phone:tw-px-[10px] tw-border tw-flex tw-items-center tw-font-bold'><div className='tw-line-clamp-2 phone:tw-line-clamp-3 tw-break-words'>Specification</div></div>
                                        <div className='tw-w-[70%] tw-h-[60px] phone:tw-h-[100px] tw-py-[10px] tw-px-[30px] tw-border tw-flex tw-items-center'><div className='tw-line-clamp-2 phone:tw-line-clamp-3 tw-break-all'>{orderInfo && orderInfo.formatSku }</div></div>
                                    </div>
                                    <div className='tw-flex tw-items-center '>
                                        <div className='tw-w-[30%] tw-h-[60px] phone:tw-h-[100px] tw-py-[10px] tw-px-[30px] phone:tw-px-[10px] tw-border tw-flex tw-items-center tw-font-bold'><div className='tw-line-clamp-2 phone:tw-line-clamp-3 tw-break-words'>Num</div></div>
                                        <div className='tw-w-[70%] tw-h-[60px] phone:tw-h-[100px] tw-py-[10px] tw-px-[30px] tw-border tw-flex tw-items-center'><div className='tw-line-clamp-2 phone:tw-line-clamp-3 tw-break-all'>{orderInfo && orderInfo.num }</div></div>
                                    </div>
                                    <div className='tw-flex tw-items-center '>
                                        <div className='tw-w-[30%] tw-h-[60px] phone:tw-h-[100px] tw-py-[10px] tw-px-[30px] phone:tw-px-[10px] tw-border tw-flex tw-items-center tw-font-bold'><div className='tw-line-clamp-2 phone:tw-line-clamp-3 tw-break-words'>Money</div></div>
                                        <div className='tw-w-[70%] tw-h-[60px] phone:tw-h-[100px] tw-py-[10px] tw-px-[30px] tw-border tw-flex tw-items-center'><div className='tw-line-clamp-2 phone:tw-line-clamp-3 tw-break-all'>{orderInfo && orderInfo.accout}</div></div>
                                    </div>
                                    <div className='tw-flex tw-items-center '>
                                        <div className='tw-w-[30%] tw-h-[60px] phone:tw-h-[100px] tw-py-[10px] tw-px-[30px] phone:tw-px-[10px] tw-border tw-flex tw-items-center tw-font-bold'><div className='tw-line-clamp-2 phone:tw-line-clamp-3 tw-break-words'>Username</div></div>
                                        <div className='tw-w-[70%] tw-h-[60px] phone:tw-h-[100px] tw-py-[10px] tw-px-[30px] tw-border tw-flex tw-items-center'><div className='tw-line-clamp-2 phone:tw-line-clamp-3 tw-break-all'>{orderInfo && `${orderInfo.last_name} ${orderInfo.first_name}`}</div></div>
                                    </div>
                                    <div className='tw-flex tw-items-center '>
                                        <div className='tw-w-[30%] tw-h-[60px] phone:tw-h-[100px] tw-py-[10px] tw-px-[30px] phone:tw-px-[10px] tw-border tw-flex tw-items-center tw-font-bold'><div className='tw-line-clamp-2 phone:tw-line-clamp-3 tw-break-words'>Phone</div></div>
                                        <div className='tw-w-[70%] tw-h-[60px] phone:tw-h-[100px] tw-py-[10px] tw-px-[30px] tw-border tw-flex tw-items-center'><div className='tw-line-clamp-2 phone:tw-line-clamp-3 tw-break-all'>{orderInfo && orderInfo.telephone}</div></div>
                                    </div>
                                    <div className='tw-flex tw-items-center '>
                                        <div className='tw-w-[30%] tw-h-[60px] phone:tw-h-[100px] tw-py-[10px] tw-px-[30px] phone:tw-px-[10px] tw-border tw-flex tw-items-center tw-font-bold'><div className='tw-line-clamp-2 phone:tw-line-clamp-3 tw-break-words'>Time</div></div>
                                        <div className='tw-w-[70%] tw-h-[60px] phone:tw-h-[100px] tw-py-[10px] tw-px-[30px] tw-border tw-flex tw-items-center'><div className='tw-line-clamp-2 phone:tw-line-clamp-3 tw-break-all'>{orderInfo && DateTimeUtil.getFriendlyTime(orderInfo.createtime * 1000)}</div></div>
                                    </div>
                                    <div className='tw-flex tw-items-center '>
                                        <div className='tw-w-[30%] tw-h-[60px] phone:tw-h-[100px] tw-py-[10px] tw-px-[30px] phone:tw-px-[10px] tw-border tw-flex tw-items-center tw-font-bold'><div className='tw-line-clamp-2 phone:tw-line-clamp-3 tw-break-words'>Address</div></div>
                                        <div className='tw-w-[70%] tw-h-[60px] phone:tw-h-[100px] tw-py-[10px] tw-px-[30px] tw-border tw-flex tw-items-center'><div className='tw-line-clamp-2 phone:tw-line-clamp-3 tw-break-all'>{orderInfo && `${orderInfo.province} ${orderInfo.city} ${orderInfo.address}`}</div></div>
                                    </div>


                                </div>


                            </div>


                        </div>
                       
                    </div>

                </div>
            </div>
        );
    }

}


function mapStateToProps(state) {
    return {
    };
}

function mapDispatchToProps(dispatch) {
    return {
        orderActions: bindActionCreators(orderActionCreator, dispatch),
        // tagActions: bindActionCreators(tagActionCreator, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderDetailPage);

export const BASE_URL = window.location.protocol + "//" + window.location.host;

const Endpoint = {
    ME: '/user/getUserinfo',
    ORDER: '/index.php/api/product/pay',
    ORDER_LIST: '/index.php/api/product/getOrderlist',
    ORDER_DETAIL: '/index.php/api/product/getOrder',

    LOGIN: '/index.php/api/user/login',
    LOGOUT: '/index.php/api/user/logout',
    REGISTER: '/index.php/api/user/register',

    STORY_DETAIL: '/index.php/api/Tiezi/getTiezi',
    STORY_COMMENT_LIST: '/index.php/api/Pinglun/getPinglunlisttiezi',
    PRODUCT_DETAIL: '/index.php/api/Product/getProduct',
    PRODUCT_COMMENT_LIST: '/index.php/api/Productpinglun/getProductpinglunlistproduct',

    
    
}
export default Endpoint;
